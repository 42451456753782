import React from 'react';
import Layout from '../components/Layout';
import content from "../content/home";

const HomePage = () => (
  <Layout>
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-around',
      maxWidth: '500px',
      margin: '0 auto'
    }}>
      <a href={content.mainLink}>
        <img
          src={content.mainImage}
          alt="EP Cover"
          className="home-image"
        />
      </a>
      <h2 className="welcome-message">
        {content.welcomeMessage}
      </h2>
      </div>
    </Layout>
);

export default HomePage;
